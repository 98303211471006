import { rapidReady, ylkObjToString } from '../../../../lib/utils/rapid'

const LOCAL_STORAGE_VARIABLE_NAME = 'aolRecentlyPlayedGames'
const WRAPPER_DIV_NAME = 'recently-played-games'
const GAME_LIST_API = '/api/v1/games'
const LEGACY_AOL_COOKE_NAME = 'gLastPlayed'

export default (() => {
  class GameRecentlyPlayed {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }

      this.trayClass = 'recently-played-games-tray'
      this.trayItemClass = 'recently-played-games-tray-item'
      this.trayItemImageWrapClass = 'recently-played-image-wrap'
      this.trayItemLinkClass = 'games-link-overlay'
      this.trayItemTextWrapClass = 'recently-played-games-text-wrap'

      this.init()
    }

    init() {
      let storage = window.localStorage.getItem(LOCAL_STORAGE_VARIABLE_NAME)
      if (!storage) {
        // Add support for legacy cookie so users don't lose their last played games
        let legacyCookie = window.NEXUtils.getCookie(LEGACY_AOL_COOKE_NAME)
        if (legacyCookie) {
          window.NEXUtils.deleteCookie(LEGACY_AOL_COOKE_NAME, 'games')
          window.NEXUtils.deleteCookie(LEGACY_AOL_COOKE_NAME)
          // Parse cookie, split on comma and then convert to expected localStorage format [{id: 1}, {id: 2}]
          legacyCookie = decodeURIComponent(legacyCookie)
            .split(',')
            .reduce((result, item) => {
              result.push({ id: parseInt(item, 10) })
              return result
            }, [])
          // Remove duplicates if they exist as they can exist in Legacy cookie
          legacyCookie = legacyCookie.filter(
            (item, index, self) => index === self.findIndex(t => t.id === item.id)
          )
          window.localStorage.setItem(LOCAL_STORAGE_VARIABLE_NAME, JSON.stringify(legacyCookie))
          storage = window.localStorage.getItem(LOCAL_STORAGE_VARIABLE_NAME)
        } else {
          this.deleteRecentlyPlayedTray()
          return
        }
      }

      try {
        storage = JSON.parse(storage)
      } catch (e) {
        // If for some reason localStorage is corrupted delete div and delete localStorage
        this.deleteRecentlyPlayedTray()
        window.localStorage.removeItem(LOCAL_STORAGE_VARIABLE_NAME)
        return
      }

      const tray = document.getElementById(this.trayClass)
      fetch(GAME_LIST_API)
        .then(response => {
          response.json().then(respJson => {
            const storageGames = []
            storage.forEach(ele => {
              const gameInfoFound = respJson.find(e => e.id === ele.id)
              if (gameInfoFound) {
                storageGames.push(gameInfoFound)
              }
            })
            storageGames.forEach((game, index) => {
              const ylk = {
                cpos: index + 1,
                slk: game.name
              }

              const wrapperDiv = document.createElement('li')
              wrapperDiv.classList.add(this.trayItemClass)

              const imageWrap = document.createElement('div')
              imageWrap.classList.add(this.trayItemImageWrapClass)

              const image = document.createElement('img')
              image.src = game.imageUrl
              image.alt = `Logo for ${game.name}`

              const link = document.createElement('a')
              link.classList.add(this.trayItemLinkClass)
              link.href = `/games/play/${game.entityPagePath}/`
              link.setAttribute('data-ylk', ylkObjToString(ylk))
              const textWrap = document.createElement('div')
              textWrap.classList.add(this.trayItemTextWrapClass)

              const playGameButton = document.createElement('button')
              playGameButton.textContent = 'Play Game'
              ylk.tar_uri = `/games/play/${game.entityPagePath}/`
              playGameButton.setAttribute('data-ylk', ylkObjToString(ylk))

              /**
               * <div class="recently-played-games-tray-item">
               *     <div class="recently-played-image-wrap">
               *         <img src="${game.imageUrl}" alt="Logo for ${game.name}" />
               *         <a href="/games/play/${game.entityPagePath}">
               *             <div class="recently-played-games-text-wrap">
               *                <button>Play Game</button>
               *             </div>
               *         </a>
               *     </div>
               * </div>
               */

              textWrap.appendChild(playGameButton)
              link.appendChild(textWrap)
              imageWrap.appendChild(image)
              imageWrap.appendChild(link)
              wrapperDiv.appendChild(imageWrap)
              tray.appendChild(wrapperDiv)
              this.refreshRapid()
            })
          })
        })
        .catch(err => {
          console.error(`Cannot receive Games List from API ${err}`)
        })
    }

    deleteRecentlyPlayedTray() {
      const recentlyPlayedNode = document.getElementById(WRAPPER_DIV_NAME)
      recentlyPlayedNode.parentNode.removeChild(recentlyPlayedNode)
    }

    refreshRapid() {
      const recentlyPlayedNode = document.getElementById(WRAPPER_DIV_NAME)
      rapidReady(rapid => {
        rapid.refreshModule(recentlyPlayedNode, false)
      })
    }
  }

  return GameRecentlyPlayed
})()
